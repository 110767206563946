document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const percentVal = $(".progressBox").data("percentval");

    if ($(".projectsSlider .progressBox").length > 0) {
      if ($(".round").length > 0) {
        $(".round").each(function () {
          let $round = $(this),
            roundRadius = $round.find("circle").attr("r"),
            roundPercent = $round.data("percent"),
            roundCircum = 2 * roundRadius * Math.PI,
            roundDraw = (roundPercent * roundCircum) / 100;

          switch (roundPercent) {
            case 100:
              $round.removeClass("none");
              $round.addClass("full");
              $round.css("stroke-dasharray", roundDraw + " 999");
              break;
            case 0:
              $round.addClass("none");
              break;
            default:
              $round.removeClass("none");
              $round.css("stroke-dasharray", roundDraw + " 999");
          }
        });
      }
    }
  });
});
